import React from 'react';
import { Routes, Route, BrowserRouter as Router, useNavigate } from 'react-router-dom';
import '../src/styles/tailwind.css';
import VoiceRecorder from './Component/VoiceRecorder';
import SettingsLayout from './Component/SettingsLayout';
import Sidebar1 from './Component/sidebar1';
import LandingPage from './Pages/LandingPage';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';



// const Layout = () => {
//   const navigate = useNavigate();

  
//   const handleSettingsClick = () => {
//     navigate('/settings'); 
//   };

//   return (
//     <div className="flex h-screen">
    
//       <Sidebar1 onSettingsClick={handleSettingsClick} />

      
//       <div className="flex-1 overflow-y-scroll p-6 bg-gray-100">
//         <Routes>
//           <Route path="/" element={<VoiceRecorder />} />
//           <Route path="/settings/*" element={<SettingsLayout />} />
//         </Routes>
//       </div>
//     </div>
//   );
// };

function App() {
  return (
   
 

    // <LandingPage/>




    //base 
  <>
      <Navbar/>
      <VoiceRecorder/>
      <Footer/>
    </>
  
    //sidebar & base
    // <Router>
    //   <div className="App">
    //     <Routes>
    //       <Route path="/*" element={<Layout />} />
    //     </Routes>
    //   </div>
    // </Router>
  );
}

export default App;
