import React, { useState, useEffect} from 'react';
import './Navbar.css';
import logo from '../Assist/navbarLogo.png';
import ModalForm from './Model/Model';

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown toggle
  const [navbarTransparent, setNavbarTransparent] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown on small screens
  };



  const handleScroll = () => {
    const scrollY = window.scrollY;
    setNavbarTransparent(scrollY > 50); // Adjust this value for when to change transparency
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <nav className={`navbar ${navbarTransparent ? 'transparent' : ''}`}>
        <img src={logo} alt="Ardra AI Logo" className="navbar-logo" />
        <span>ardraGPT</span>

        {/* Hidden on small screens */}
        <button className="navbar-demo-link desktop-only" onClick={openModal}>
          Talk to a Generative AI Expert
        </button>

        {/* Toggle button for small screens */}
        <button className="navbar-toggle" onClick={toggleDropdown}>
          ☰
        </button>
      </nav>

      {/* Dropdown for small devices */}
      {isDropdownOpen && (
        <div className="navbar-dropdown">
         
          <a href="#contact" className="navbar-dropdown-link">About Us</a>
          <button className="navbar-demo-link" onClick={openModal}>
            Talk to a Generative AI Expert
          </button>
        </div>
      )}

      <ModalForm isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default Navbar;
